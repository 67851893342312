import { Alchemy, Network } from "alchemy-sdk";

export const ALCHEMY_API_KEY = process.env.REACT_APP_ALCHEMY_API_KEY;

export const SUDO = "0x3446Dd70B2D52A6Bf4a5a192D9b0A161295aB7F9";
export const XMON = "0x3aaDA3e213aBf8529606924d8D1c55CbDc70Bf74";
export const WRAPPED_SUDO = "0xd6967f444Ce73B5e9a4d186D22a5FA59017311b8";

export const alchemy = new Alchemy({
  apiKey: ALCHEMY_API_KEY,
  network: Network.ETH_MAINNET,
});
