import styled from "styled-components";
import colors from "../../design/colors";
import { StyledInput } from "../../components/Input";
import useWSudo from "../../hooks/useWSudo";
import { useMemo } from "react";
import { ethers } from "ethers";

const Container = styled.div`
  display: flex;
  justify-content: center;
  margin: 5px;
`;

const SwapContainer = styled.div`
  background-color: white;
  padding: 20px;
  margin-top: -95px;
  border-radius: 35px;
  flex-direction: column;
  border: 1px solid ${colors.border.one};
  width: 500px;
  display: flex;
`;

const TitleContainer = styled.h2`
  text-align: center;
  margin-bottom: 5px;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  border: 3px solid ${colors.border.one};
  border-radius: 35px;
  padding: 10px 20px 10px 20px;
`;

const BalanceContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Balance = styled.h3`
  color: ${colors.background.two};
`;

const BalanceSmall = styled.h4`
  color: ${colors.background.two};
  margin-top: 30px;
`;

const XMONImage = styled.img`
  margin-right: 15px;
  margin-top: 4px;
  height: 25px;
  width: 25px;
  border-radius: 10px;
`;

const Break = styled.hr`
  background-color: ${colors.background.two}30;
  border: 1px solid ${colors.border.one};
  color: white;
  width: 100%;
`;

const MaxButton = styled.button`
  background-color: ${colors.background.three}90;
  width: 50px;
  height: 30px;
  font-size: 15px;
  border: none;
  margin-top: 15px;
  border-radius: 10px;
  &:hover {
    background-color: ${colors.background.two}60;
    cursor: pointer;
  }
`;

const OutputContainer = styled.div`
  display: flex;
  justify-content: space-between;
  border: 1px solid ${colors.border.one};
  border-radius: 35px;
  margin-top: 0px;
  padding-left: 20px;
  padding-right: 20px;
`;

const OutputSudo = styled.div`
  display: flex;
  padding-top: 3px;
`;

const SudoImage = styled.img`
  margin-right: 10px;
  margin-top: 13px;
  height: 25px;
  width: 25px;
  border-radius: 10px;
`;

const SudoAmount = styled.p``;

const Link = styled.a``;

const ButtonContainer = styled.div`
  display: flex;
`;

const Button = styled.button<{ disabled?: boolean }>`
  padding: 20px;
  margin: 30px 2px 2px 2px;
  font-size: 18px;
  width: 100%;
  font-weight: 600;
  color: white;
  border-radius: 20px;
  border: solid 1px ${colors.border.one};
  background-color: ${colors.theme.green};
  &:hover:enabled {
    background-color: ${colors.background.three};
    cursor: pointer;
  }
  &:disabled {
    background-color: ${colors.background.two};
  }
`;

const Contract = styled.p`
  text-align: center;
  margin-top: 25px;
  margin-bottom: 0px;
`;

function Swap() {
  const {
    xmonBalance,
    wsudoBalance,
    xmonAmount,
    xmonAmountBN,
    onXMONAmountChange,
    approveXMON,
    setMax,
    approved,
    lockXMON,
  } = useWSudo();

  const xmonBalanceFormatted = useMemo(
    () => ethers.utils.formatEther(xmonBalance),
    [xmonBalance]
  );
  const wsudoBalanceFormatted = useMemo(
    () => ethers.utils.formatEther(wsudoBalance),
    [wsudoBalance]
  );
  const sudoAmount = useMemo(
    () => ethers.utils.formatEther(xmonAmountBN.mul(10000)),
    [xmonAmountBN]
  );

  return (
    <Container>
      <SwapContainer>
        <TitleContainer>
          Lock{" "}
          <Link
            href="https://etherscan.io/token/0x3aaDA3e213aBf8529606924d8D1c55CbDc70Bf74"
            target="_blank"
            rel="noreferrer"
          >
            $XMON
          </Link>
          , get{" "}
          <Link
            href="https://etherscan.io/token/0xd6967f444ce73b5e9a4d186d22a5fa59017311b8"
            target="_blank"
            rel="noreferrer"
          >
            $wSUDO
          </Link>
        </TitleContainer>
        <p>
          wSUDO is a transferrable, tradeable version of SUDO. It can be
          redeemed for SUDO once SUDO is transferrable.
        </p>
        <Break />
        <BalanceContainer>
          <Balance>XMON Balance: {xmonBalanceFormatted}</Balance>
          <MaxButton onClick={setMax}>Max</MaxButton>
        </BalanceContainer>
        <InputContainer>
          <XMONImage src="/xmon.png" alt="XMON" />
          <StyledInput
            autoFocus
            type="number"
            className="form-control"
            aria-label="eth-quantity"
            placeholder="0"
            onChange={onXMONAmountChange}
            value={xmonAmount}
          />
        </InputContainer>
        <BalanceSmall>wSUDO Balance: {wsudoBalanceFormatted}</BalanceSmall>
        <OutputContainer>
          <Balance>You will receive</Balance>
          <OutputSudo>
            <SudoImage src="/sudo.png" alt="SUDO" />
            <SudoAmount>{sudoAmount}</SudoAmount>
          </OutputSudo>
        </OutputContainer>
        <ButtonContainer>
          <Button onClick={approveXMON} disabled={approved}>
            Approve
          </Button>
          <Button onClick={lockXMON} disabled={!approved}>
            Lock
          </Button>
        </ButtonContainer>
        <Contract>
          <Link
            href="https://etherscan.io/address/0xd6967f444ce73b5e9a4d186d22a5fa59017311b8"
            target="_blank"
            rel="noreferrer"
          >
            Contract
          </Link>
          {" - "}
          <Link
            href="https://app.uniswap.org/#/swap?exactField=input&inputCurrency=0x3aaDA3e213aBf8529606924d8D1c55CbDc70Bf74&outputCurrency=0xd6967f444Ce73B5e9a4d186D22a5FA59017311b8"
            target="_blank"
            rel="noreferrer"
          >
            Trade
          </Link>
          {" - "}
          <Link
            href="https://app.uniswap.org/#/add/0x3aaDA3e213aBf8529606924d8D1c55CbDc70Bf74/0xd6967f444Ce73B5e9a4d186D22a5FA59017311b8/3000"
            target="_blank"
            rel="noreferrer"
          >
            LP
          </Link>
          {" - "}
          <Link
            href="https://info.uniswap.org/#/tokens/0xd6967f444ce73b5e9a4d186d22a5fa59017311b8"
            target="_blank"
            rel="noreferrer"
          >
            Uniswap
          </Link>
          {" - "}
          <Link
            href="https://www.defined.fi/eth/0xd6967f444ce73b5e9a4d186d22a5fa59017311b8"
            target="_blank"
            rel="noreferrer"
          >
            Defined
          </Link>
        </Contract>
      </SwapContainer>
    </Container>
  );
}

export default Swap;
