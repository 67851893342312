import styled from "styled-components";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import colors from "../../design/colors";

const Heading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

const LogoContainer = styled.div`
  margin: 10px 10px 10px 0px;
  padding: 10px;
`;

const WalletButton = styled.div`
  padding: 10px 20px;
  border-radius: 7px;
  border: none;
  background-color: white;
  font-weight: 500;
  border: 1px solid ${colors.border.one};

  &:hover {
    background-color: #ffffff90;
    cursor: pointer;
  }
`;

const WalletButtonContainer = styled.div`
  margin: 15px 15px 15px 0px;
  padding: 10px;
`;

export const CustomButton = () => {
  return (
    <ConnectButton.Custom>
      {({
        account,
        chain,
        openAccountModal,
        openChainModal,
        openConnectModal,
        mounted,
      }) => {
        // Note: If your app doesn't use authentication, you
        // can remove all 'authenticationStatus' checks
        const ready = mounted;
        const connected = ready && account && chain;

        return (
          <div
            {...(!ready && {
              "aria-hidden": true,
              style: {
                opacity: 0,
                pointerEvents: "none",
                userSelect: "none",
              },
            })}
          >
            {(() => {
              if (!connected) {
                return (
                  <WalletButton onClick={openConnectModal}>
                    Connect Wallet
                  </WalletButton>
                );
              }

              if (chain.unsupported) {
                return (
                  <WalletButton onClick={openChainModal}>
                    Wrong Network
                  </WalletButton>
                );
              }

              return (
                <div style={{ display: "flex", gap: 12 }}>
                  {/* <WalletButton
                    onClick={openChainModal}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    {chain.hasIcon && (
                      <img
                        alt={chain.name ?? "Chain icon"}
                        src="/eth.png"
                        style={{ width: 20, height: 20, marginRight: 10 }}
                      />
                    )}
                    {chain.name}
                  </WalletButton> */}

                  <WalletButton onClick={openAccountModal}>
                    {account.displayName}
                  </WalletButton>
                </div>
              );
            })()}
          </div>
        );
      }}
    </ConnectButton.Custom>
  );
};

function Header() {
  return (
    <Heading>
      <LogoContainer>
        <div style={{ height: "150px", fontSize: "50px", paddingLeft: "15px" }}>🦑</div>
      </LogoContainer>
      <WalletButtonContainer>
        <CustomButton />
      </WalletButtonContainer>
    </Heading>
  );
}

export default Header;
