const colors = {
  border: {
    one: "#E0DFDF",
  },
  background: {
    one: "#14141b",
    two: "#6c757d",
    three: "#0d6efd",
    four: "#14141b",
    five: "#14141b",
    six: "#14141b",
    seven: "#14141b",
    eight: "#14141b",
  },
  theme: {
    green: "#8181cf",
    pink: "#7c93f8",
  },
};

export default colors;
