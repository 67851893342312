import { BigNumber, constants, ethers } from "ethers";
import { useMemo, useState, useCallback, useEffect } from "react";
import { WRAPPED_SUDO, XMON } from "../constants";
import { ERC20__factory, WSUDO__factory } from "../types";
import { useAccount, useProvider, useSigner } from "wagmi";
import { tryParseUnits, stripInputValue } from "../utils";

function useWSudo(): {
  xmonBalance: BigNumber;
  wsudoBalance: BigNumber;
  xmonAmount: string;
  xmonAmountBN: BigNumber;
  approved: boolean;
  approveXMON: () => Promise<void>;
  setXMONAmount: (value: string) => void;
  onXMONAmountChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  lockXMON: () => Promise<void>;
  setMax: () => void;
} {
  const provider = useProvider();
  const { data: signer } = useSigner();
  const { address } = useAccount();

  const [xmonAmount, setXMONAmount] = useState<string>("");
  const [xmonBalance, setXMONBalance] = useState<BigNumber>(BigNumber.from(0));
  const [xmonApproval, setXMONApproval] = useState<BigNumber>(
    BigNumber.from(0)
  );
  const [wsudoBalance, setWSUDOBalance] = useState<BigNumber>(
    BigNumber.from(0)
  );

  const xmon = useMemo(
    () => (provider ? ERC20__factory.connect(XMON, provider) : undefined),
    [provider]
  );
  const wsudo = useMemo(
    () =>
      provider ? WSUDO__factory.connect(WRAPPED_SUDO, provider) : undefined,
    [provider]
  );

  const xmonAmountBN: BigNumber = useMemo(
    () => tryParseUnits(stripInputValue(xmonAmount), 18),
    [xmonAmount]
  );
  const approved = useMemo(
    () => xmonApproval.gte(xmonAmountBN),
    [xmonApproval, xmonAmountBN]
  );

  const fetchXMONBalance = useCallback(async () => {
    if (provider && address && xmon) {
      try {
        const balance = await xmon.balanceOf(address);
        setXMONBalance(balance);
      } catch (error) {
        console.error(error);
      }
    }
  }, [provider, address, xmon]);

  const fetchWSUDOBalance = useCallback(async () => {
    if (provider && address && wsudo) {
      try {
        const balance = await wsudo.balanceOf(address);
        setWSUDOBalance(balance);
      } catch (error) {
        console.error(error);
      }
    }
  }, [provider, address, wsudo]);

  const fetchXMONApproval = useCallback(async () => {
    if (provider && address && xmon) {
      try {
        const allowance = await xmon.allowance(address, WRAPPED_SUDO);
        setXMONApproval(allowance);
      } catch (error) {
        console.error(error);
      }
    }
  }, [provider, address, xmon]);

  const onXMONAmountChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setXMONAmount(e.target.value);
    },
    [setXMONAmount]
  );

  const setMax = useCallback(() => {
    setXMONAmount(ethers.utils.formatEther(xmonBalance));
  }, [xmonBalance]);

  const approveXMON = useCallback(async () => {
    if (signer && address) {
      try {
        const tx = await ERC20__factory.connect(XMON, signer).approve(
          WRAPPED_SUDO,
          constants.MaxUint256
        );
        await tx.wait();

        setXMONApproval(constants.MaxUint256);
      } catch (error) {
        console.error(error);
      }
    }
  }, [signer, address]);

  const lockXMON = useCallback(async () => {
    if (signer && address) {
      try {
        const tx = await WSUDO__factory.connect(WRAPPED_SUDO, signer).lock(
          xmonAmountBN,
          address
        );
        await tx.wait();

        await Promise.all([fetchXMONBalance(), fetchWSUDOBalance()]);
      } catch (error) {
        console.error(error);
      }
    }
  }, [signer, address, xmonAmountBN, fetchXMONBalance, fetchWSUDOBalance]);

  useEffect(() => {
    fetchXMONBalance();
    fetchWSUDOBalance();
    fetchXMONApproval();
  }, [fetchXMONBalance, fetchWSUDOBalance, fetchXMONApproval]);

  return {
    xmonBalance,
    wsudoBalance,
    xmonAmount,
    xmonAmountBN,
    setXMONAmount,
    onXMONAmountChange,
    approved,
    approveXMON,
    setMax,
    lockXMON,
  };
}

export default useWSudo;
