import { ethers, BigNumber } from "ethers";

export function stripInputValue(value: string): string {
  if (value === "" || value === "0" || value === "0.0" || value.endsWith(".")) {
    return "0";
  } else if (value.startsWith(".")) {
    return "0" + value;
  }

  return value;
}

export function tryParseUnits(
  value: string,
  decimals: number,
  setError?: (value: boolean) => void
): BigNumber {
  let parsedValue: BigNumber = BigNumber.from("0");
  try {
    parsedValue = ethers.utils.parseUnits(value, decimals);
    if (setError) {
      setError(false);
    }
  } catch (error) {
    console.warn(error);

    if (setError) {
      setError(true);
    }
  }
  return parsedValue;
}
